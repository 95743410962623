import React, { useMemo } from "react"
import { get } from "lodash"
// import { useStaticQuery } from 'gatsby'
// import { linkResolver } from '../../utils/linkResolver'
// import { getFilter } from '../../utils/getFilter'
import ProductCard from "./ProductCard"
import { filterdCoffeesPlp } from "../../utils/filterdCoffeesPlp"
import { Strings } from "../../utils/strings"
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'

const PlpCoffees = ({
  index,
  page,
  products,
  selectedFilter,
  toggleSampleForm,
  setSampleForm,
}) => {
  console.log("[PlpCoffees.js] products", products)

  const parsedProducts = useMemo(() => {
    if (!products) {
      return null
    }

    return products
      .map(d => {
        const node = d.node

        return {
          title: get(node, "title"),
          meta: get(node, "_meta"),
          slug: get(node, "_meta.uid"),
          sku: get(node, "sku"),
          fazenda: get(node, "fazenda"),
          family: get(node, "family"),
          main_image: get(node, "main_image"),
          notes: get(node, "notes"),
          harvest: get(node, "harvest"),
          variety: get(node, "variety"),
          packaging: get(node, "packaging"),
          processing: get(node, "processing"),
          harvest_year: get(node, "harvest_year"),
          altitude: get(node, "altitude"),
          current_location: get(node, "current_location"),
          bags_available: get(node, "bags_available"),
          hubspot_form_id: get(node, "hubspot_form_id"),
          hubspot_portal_id: get(node, "hubspot_portal_id"),
          form_title: get(node, "form_title"),
          form_intro: get(node, "form_intro"),
          details: get(node, "details"),
        }
      })
      .sort((a, b) => (a.slug < b.slug ? 1 : b.slug < a.slug ? -1 : 0))
  }, [products])

  const filterdProducts = filterdCoffeesPlp(parsedProducts, selectedFilter)
  const allStrings = Strings(page)

  return (
    <section key={index} className={"plp_all_coffees"}>
      <div className="list_product_card">
        <a
          className="plp_all_coffees__anchor"
          id="all-coffees"
          href={"#all-coffees"}
        >
          &nbsp;
        </a>

        {filterdProducts.map((item, index) => {
          return (
            <div className="list_product_card__col" key={index}>
              <ProductCard
                item={item}
                index={index}
                toggleSampleForm={toggleSampleForm}
              />
            </div>
          )
        })}
      </div>

      {filterdProducts.length === 0 ? (
        <div className="plp_all_coffees__zero_reults">
          <h4>{allStrings?.main_no_results_found ?? "No results found."}</h4>
        </div>
      ) : null}
    </section>
  )
}

export default PlpCoffees
