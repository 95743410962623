import React, { useEffect, useMemo, useRef, useState } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PlpHeader from "../components/slices/PlpHeader"
import PlpFamilyCountry from "../components/slices/PlpFamilyCountry"
import EditorialBanner from "../components/slices/EditorialBanner"
import IlustrationEditorialBanner from "../components/slices/IlustrationEditorialBanner"
import PlpCoffees from "../components/slices/PlpCoffees"
// import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import { getFilter } from "../utils/getFilter"
import scrollTo from "gatsby-plugin-smoothscroll"
import PdpRequestSample from "../components/slices/PdpRequestSample"

// Display the title, date, and content of the Post
const PlpBody = ({ page, products }) => {
  const lang = useMemo(() => get(page, "_meta.lang", "en-us"), [page])

  console.log('PlpBody products', products)

  // const [sort, setSort] = useQueryParam("sort", StringParam) - This was refresing the page
  const [sort, setSort] = useState("family")
  const [showPlpHeaderBar, setShowPlpHeaderBar] = useState("")
  const plpContentElem = useRef()
  const [selectedFilter, setSelectedFilter] = useState([])

  // console.log('products', products)

  const productFilters = useMemo(() => {
    let filters = []
    let uniqueFilters = []
    if (products) {
      // console.log('[archive.js] products', products)
      products.forEach(p => {
        // console.log('[PlpBody.js] p', p)
        const filters = getFilter(p)

        if (filters?.length) {
          filters.forEach(filter => {
            // console.log('filter', filter)

            const categoryValue = get(filter, "category")
            const filterName = get(filter, "name")
            const filterValue = get(filter, "value")

            if (filterValue && !uniqueFilters[filterValue]) {
              uniqueFilters[filterValue] = {
                name: filterName,
                value: filterValue,
                category: categoryValue,
              }
            }
          })
        }
      })
    }

    return [...filters, ...Object.values(uniqueFilters)]
  }, [products])

  // console.log('all filters', productFilters)

  const changeSort = sort => {
    setSort(sort)

    if (sort === "all-coffees") {
      scrollTo("#all-coffees")
    } else {
      scrollTo(scrollSpyList[0])
    }
    // console.log('[archive.js] handleSelectFilter filter', filter)
  }

  if (sort === "" || sort === undefined || sort === null) {
    setSort("family")
  }

  const handleScroll = () => {
    // console.log('body scrollTop', document.body.scrollTop || document.documentElement.scrollTop)
    // console.log('offsetTop', plpContentElem.current.offsetTop)

    // console.log('plpContentElem', plpContentElem)
    if (plpContentElem.current !== null) {
      if (
        plpContentElem &&
        plpContentElem?.current.offsetTop - 3 <
          (document.body.scrollTop || document.documentElement.scrollTop)
      ) {
        setShowPlpHeaderBar(" show")
      } else {
        setShowPlpHeaderBar("")
      }
    }
  }

  const handleSelectFilter = filter => {
    let currrentSelectedFilter = selectedFilter

    if (
      Object.values(currrentSelectedFilter).filter(
        f => f?.value === filter?.value
      ).length > 0
    ) {
      // Remove from filter

      // console.log('handleSelectFilter has filter')
      let index = Object.values(currrentSelectedFilter).findIndex(
        f => f?.value === filter?.value
      )

      currrentSelectedFilter.splice(index, 1)
      setSelectedFilter([...currrentSelectedFilter])
    } else {
      // Add to filter

      currrentSelectedFilter.push(filter)
      setSelectedFilter([...currrentSelectedFilter])
    }

    // console.log('currrentSelectedFilter.length', currrentSelectedFilter.length)

    if (currrentSelectedFilter.length > 0) {
      setSort("all-coffees")
    }

    scrollTo("#all-coffees")

    // setSelectedFilter(filter)
    // console.log('[handleSelectFilter] selectedFilter', selectedFilter)
  }

  const load = () => {
    if (window.location.hash) {
      // Fragment exists
      // console.log('window.location.hash', window.location.hash)

      scrollTo(window.location.hash)
    } else {
      // Fragment doesn't exist
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    load()

    // if(window.location.hash) {
    //   // Fragment exists
    //   console.log('window.location.hash', window.location.hash)

    //   scrollTo(window.location.hash)
    // } else {
    //   // Fragment doesn't exist
    // }
  }, [])

  let scrollSpyList = []

  // Toggle Sample Form State

  const [sampleFormState, setSampleFormState] = useState("")
  const toggleSampleForm = product => {
    // setSortButtonState(!sortButtonState)
    if (sampleFormState === "") {
      setSampleFormState(" open")
      setSampleForm(product)
    } else {
      setSampleFormState("")
    }
  }

  // Form content

  const [sampleForm, setSampleForm] = useState()
  // const setSampleForm = () => {
  //   // setSortButtonState(!sortButtonState)
  //   if(sampleFormState === '') {
  //     setSampleFormState(' open')
  //   } else {
  //     setSampleFormState('')
  //   }
  // }

  const renderContainer = (container, index, products) => {
    // console.log('container', container.type)

    if (sort === "family") {
      if (container && container.type === "family_products") {
        const family_uid = get(container, "primary.coffee_family._meta.uid")
        if (family_uid && !scrollSpyList[family_uid]) {
          scrollSpyList.push("uid-" + family_uid)
        }
        return (
          <PlpFamilyCountry
            key={index}
            data={container}
            index={index}
            lang={lang}
            products={products}
            toggleSampleForm={toggleSampleForm}
          />
        )
      }

      if (container && container.type === "editorial__image___text") {
        if (scrollSpyList && !scrollSpyList["i-t-" + index]) {
          scrollSpyList.push("i-t-" + index)
        }
        return <EditorialBanner key={index} data={container} index={index} />
      }
    }

    if (container && container.type === "illustration_banner") {
      if (scrollSpyList && !scrollSpyList["i-b-" + index]) {
        scrollSpyList.push("i-b-" + index)
      }
      return (
        <IlustrationEditorialBanner
          key={index}
          data={container}
          index={index}
        />
      )
    }

    // if (container && container.type === "related_case_studies") {
    //   return (<RelatedEtudes key={index} data={container} index={index} />)
    // }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        <PlpHeader
          page={page}
          changeSort={changeSort}
          sort={sort}
          showPlpHeaderBar={showPlpHeaderBar}
          filters={productFilters}
          handleSelectFilter={handleSelectFilter}
          selectedFilter={selectedFilter}
          scrollSpyItems={scrollSpyList}
        />

        <div className="plp_content" ref={plpContentElem}>
          {sort === "all-coffees" ? (
            <PlpCoffees
              products={products}
              selectedFilter={selectedFilter}
              toggleSampleForm={toggleSampleForm}
              page={page}
            />
          ) : (
            ""
          )}

          {page &&
            page?.body?.map((container, index) =>
              renderContainer(container, index, products)
            )}
        </div>

        <PdpRequestSample
          data={sampleForm}
          state={sampleFormState}
          toggleSampleForm={toggleSampleForm}
        />
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allPlps.edges[0]


  // console.log("doc", doc.node.body)
  // console.log("family_products", doc?.node?.body.filter(item => item.type === "family_products").map((item, index) =>  item.fields).flat())

  // const allProducts = props.data.prismic.allProducts.edges
  const allProducts = doc?.node?.body.filter(item => item.type === "family_products").map((item, index) =>  item.fields).flat()
  
  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} meta={meta}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <PlpBody page={doc.node} products={allProducts} />
    </Layout>
  )
}

export const query = graphql`
  query plpQuery($lang: String, $uid: String) {
    prismic {
      allPlps(lang: $lang, uid: $uid) {
        edges {
          node {
            intro
            title
            seo_title
            seo_meta
            seo_image
            _meta {
              lang
              uid
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            body {
              ... on PRISMIC_PlpBodyFamily_products {
                type
                label
                primary {
                  coffee_family {
                    ... on PRISMIC_Coffee_family {
                      title
                      description
                      illustration_couple
                      illustration_individual
                      illustration_outline_couple
                      _meta {
                        uid
                        lang
                      }
                    }
                  }
                }
                ...on PRISMIC_PlpBodyFamily_products {
                  fields {
                    product {
                      ...on PRISMIC_Product {
                        _meta {
                          uid
                          lang
                          type
                        }
                        family {
                          ... on PRISMIC_Coffee_family {
                            title
                            description
                            illustration_individual
                            _meta {
                              uid
                              lang
                            }
                          }
                        }
                        
                        title
                        sku
                        notes {
                          note
                        }
                        # harvest
                        # current_location
                        # bags_available
                        # harvest_year
                        # min_order
                        # packaging
                        # processing
                        # status
                        # variety
                        # altitude
                        details {
                          title1
                          text
                          show_in_mini_card
                        }
                        main_image
                        body1 {
                          ... on PRISMIC_ProductBody1Sample_form {
                            type
                            label
                            primary {
                              target_location
                              hubspot_portal_id1
                              hubspot_form_id1
                            }
                          }
                        }
                        form_title
                        form_intro
                        fazenda {
                          ... on PRISMIC_Fazenda {
                            title
                            _linkType
                            country {
                              ... on PRISMIC_Country {
                                title
                                flag
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PlpBodyEditorial__image___text {
                type
                label
                primary {
                  title
                  text
                  image
                }
              }
              ... on PRISMIC_PlpBodyIllustration_banner {
                type
                label
                primary {
                  title
                  text
                  illustration
                  button_text
                  button_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allProducts(lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
              type
            }
            family {
              ... on PRISMIC_Coffee_family {
                title
                description
                illustration_individual
                _meta {
                  uid
                  lang
                }
              }
            }
            
            title
            sku
            notes {
              note
            }
            # harvest
            # current_location
            # bags_available
            # harvest_year
            # min_order
            # packaging
            # processing
            # status
            # variety
            # altitude
            details {
              title1
              text
              show_in_mini_card
            }
            main_image
            body1 {
              ... on PRISMIC_ProductBody1Sample_form {
                type
                label
                primary {
                  target_location
                  hubspot_portal_id1
                  hubspot_form_id1
                }
              }
            }
            form_title
            form_intro
            fazenda {
              ... on PRISMIC_Fazenda {
                title
                _linkType
                country {
                  ... on PRISMIC_Country {
                    title
                    flag
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
