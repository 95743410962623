import React, { useMemo, useState } from 'react'
import { get } from 'lodash'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Scrollspy from 'react-scrollspy'
import { useWindowSizes } from '@hooks/window'
import { Strings } from '../../utils/strings'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'

const PlpHeader = ({ index, page, changeSort, sort, showPlpHeaderBar, filters, handleSelectFilter, selectedFilter, scrollSpyItems }) => {

  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  const allStrings = Strings(page)

  const title = useMemo(() => (
    get(page, 'title[0].text', 'title placeholder')
  ), [page])

  const intro = useMemo(() => (
    get(page, 'intro[0].text', 'intro placeholder')
  ), [page])

  // console.log('scrollSpyItems', scrollSpyItems)

  // const parsedFilters = useMemo(() => {
  //   if (!filters) {
  //     return null
  //   }

  //   return filters.map((filter) => {

  //     return {
  //       category: get(filter, 'category'),
  //       name: get(filter, 'name'),
  //       value: get(filter, 'value'),
  //     }
  //   }).sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
  // }, [parsedFilters])
  const parsedFilters = filters

  // Categories

  let categories = []
  let categoriesOpenState = []
  // console.log('categories before', categories)
  parsedFilters.map((filter, index) => {
    const category = get(filter, 'category') 
    if (category && !categories.includes(category)) {
      categories.push(category)
      categoriesOpenState.push('')
    }
    return null
  })
  categoriesOpenState[0] = ' open'

  const [categoriesOpen, setCategoriesOpen] = useState(categoriesOpenState)

  // console.log('categoriesOpen', categoriesOpen)
  // console.log('categoriesOpen[]', categoriesOpen[categories[0]])

  // Toggle Sort

  const [sortButtonState, setSortButtonState] = useState('')
  const toggleSortButton = () => {
    // setSortButtonState(!sortButtonState)
    if(sortButtonState === '') {
      setSortButtonState(' open')
    } else {
      setSortButtonState('')
    }
  }

  // Toggle Refine

  const [refineState, setRefineState] = useState('')
  const toggleRefineState = () => {
    // setSortButtonState(!sortButtonState)
    if(refineState === '') {
      setRefineState(' open')
    } else {
      setRefineState('')
    }
  }

  // Handle filter change 

  const handleOnChange = (filter) => {
    if (Object.values(selectedFilter).filter(sf => sf?.value === filter.value).length > 0) {
      return true
    } else {
      return false
    }
  }

  const renderPill = (container, index) => {

    if (container && container.type === "family_products") {
      
      // let title, image_color, image_outline, uid
      const title = get(container, 'primary.coffee_family.title[0].text', 'title placeholder')
      const image_color = get(container, 'primary.coffee_family.illustration_couple')
      const image_outline = get(container, 'primary.coffee_family.illustration_outline_couple')
      const uid = get(container, 'primary.coffee_family._meta.uid')

      return (
        <button className="btn_pill" key={index} onClick={() => scrollTo('#'+uid)}>
          <span>{title}</span>
          <div className="btn_pill__imgs">
            <img className="btn_pill__imgs__outline" src={image_outline?.url} alt={image_outline?.alt ?? title} />
            <img className="btn_pill__imgs__color" src={image_color?.url} alt={image_color?.alt ?? title} />
          </div>
        </button>
      )
    } 
    
    return null
  }

  const renderPillFilter = (filter, index) => {

    const category = get(filter, 'category', 'category')
    const filter_name = get(filter, 'name', 'name')

    return (
      <button 
        className="btn_pill_small" 
        key={index} 
        onClick={() => {handleSelectFilter(filter);}}>
        <label className="label-xs">
          {category}
        </label>
        <span>
          {filter_name}
        </span>
      </button>
    )
  }

  const renderFilter = (category, filter, index) => {

    // console.log('renderFilter filter',filter)

    const name = get(filter, 'name', 'placeholder')
    const filterCategory = get(filter, 'category')

    if (filterCategory === category) {
      return (
        <li 
          key={index}
          className={handleOnChange(filter) ? 'active' : ''}>
          <button
            onClick={() => {handleSelectFilter(filter); handleOnChange(index)}}>
            <span className="accordion_list__square"></span>
            {name}
          </button>
        </li>
      )
    } else {
      return null
    }
  }

  const toggleCategoriesOpen = (index) => {

    let categoriesOpenArray = categoriesOpen
    if(categoriesOpenArray[index] === ' open') {
      categoriesOpenArray[index] = ''
    } else {
      categoriesOpenArray[index] = ' open'
    }

    // console.log('categoriesOpenArray', categoriesOpenArray)

    setCategoriesOpen([...categoriesOpenArray])

    // console.log('toggleCategoriesOpen categoriesOpen', categoriesOpen)
  }

  const renderCategory = (category, index) => {

    // console.log('renderCategory categoriesOpen', categoriesOpen)

    return (
      <div 
        className={'accordion_list' + categoriesOpen[index]}
        key={index}>
        <button
          className="accordion_list__title p-large"
          onClick={() => toggleCategoriesOpen(index)}>
          {category}
        </button>
        <ul className="accordion_list__items">

          { parsedFilters?.map((filter, index) => renderFilter(category, filter, index)) }

        </ul>
      </div>
    )
  }

  // Scroll spy offset
  let scrollSpyOffeset = -170
  isIphonePlus  ? scrollSpyOffeset = -168 :
  isIpad        ? scrollSpyOffeset = -168 :
  isIpadPro     ? scrollSpyOffeset = -168 :
  isDesktop     ? scrollSpyOffeset = -168 :
  scrollSpyOffeset = -170

  return(
    <>
      <section
        key={index}
        className={"plp_header"}>
        
        <div className="row row-justify">
          <div className="col col4 plp_header__col_text">
            <h1 className="h3">{title}</h1>
            <div className="plp_header__info">
              <p className="p-large font-book">
                {intro}
              </p>
            </div>
          
          </div>
          <div className="col col4 plp_header__col_filter">

            <div className="plp_header__filter">
              <div 
                className={"btn-dropdown"+refineState} 
                onClick={() => toggleRefineState()}
                onKeyDown={() => toggleRefineState()}
                role="button"
                tabIndex="0">
                <div className="btn-dropdown__name">
                  {allStrings?.plp_refine ?? 'Refine'}
                </div>
              </div>

              <div 
                className={"btn-dropdown hidden_ipad"+sortButtonState} 
                onClick={() => toggleSortButton()}
                onKeyDown={() => toggleSortButton()}
                role="button"
                tabIndex="0">
                <div className="btn-dropdown__name">
                  {allStrings?.plp_sort_by ?? 'Sort by'}
                </div>
                <ul className="btn-dropdown__items">
                  {/* <li 
                    role="button"
                    className={sort === 'family' ? "active" : ''} 
                    onClick={() => changeSort('family')}
                    onKeyDown={() => changeSort('family')}>
                    <span className="btn-dropdown__items__square"></span>
                    {allStrings?.plp_refine_dropdown_family ?? 'Family'}
                  </li>
                  <li 
                    role="button"
                    className={sort === 'all-coffees' ? "active" : ''} 
                    onClick={() => changeSort('all-coffees')}
                    onKeyDown={() => changeSort('all-coffees')}
                    tabIndex="0">
                    <span className="btn-dropdown__items__square"></span>
                    {allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'}
                  </li> */}

                  <li className={sort === 'family' ? "active" : ''}>
                    <button
                      onClick={() => changeSort('family')}
                      onKeyDown={() => changeSort('family')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.plp_refine_dropdown_family ?? 'Family'}
                    </button>
                  </li>

                  <li className={sort === 'all-coffees' ? "active" : ''} >
                    <button
                      onClick={() => changeSort('all-coffees')}
                      onKeyDown={() => changeSort('all-coffees')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="plp_header__achoors">
          {sort === 'family' ?
            page && page?.body?.map((container, index) => (
              renderPill(container, index)
            ))
          : 
            selectedFilter?.map((filter, index) => renderPillFilter(filter, index))
          }
        </div>

      </section>

      <div className={"plp_header_bar"+showPlpHeaderBar}>
        <div className="plp_header_bar__container">
          <div className="plp_header_bar__bg"></div>
          
          <Scrollspy 
            className="plp_header_bar__achoors"
            currentClassName="active"
            items={scrollSpyItems}
            offset={scrollSpyOffeset}>
            
            {sort === 'family' ?
              page && page?.body?.map((container, index) => (
                renderPill(container, index)
              ))
            : 
              selectedFilter?.map((filter, index) => renderPillFilter(filter, index))
            }

          </Scrollspy>

          <div className="plp_header_bar__col_filter">

            <div className="plp_header__filter">
              <div 
                role="button"
                tabIndex="0"
                className={"btn-dropdown"+refineState} 
                onKeyDown={() => toggleRefineState()}
                onClick={() => toggleRefineState()}>
                <div className="btn-dropdown__name">
                  {allStrings?.plp_refine ?? 'Refine'}
                </div>
              </div>

              <div 
                role="button"
                tabIndex="0"
                className={"btn-dropdown hidden_ipad"+sortButtonState}
                onKeyDown={() => toggleSortButton()}
                onClick={() => toggleSortButton()}>
                <div className="btn-dropdown__name">
                  {allStrings?.plp_sort_by ?? 'Sort by'}
                </div>
                <ul className="btn-dropdown__items">
                  {/* <li className={sort === 'family' ? "active" : ''} onClick={() => changeSort('family')}>
                    <span className="btn-dropdown__items__square"></span>
                    {allStrings?.plp_refine_dropdown_family ?? 'Family'}
                  </li>
                  <li className={sort === 'all-coffees' ? "active" : ''} onClick={() => changeSort('all-coffees')}>
                    <span className="btn-dropdown__items__square"></span>
                    {allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'}
                  </li> */}

                  <li className={sort === 'family' ? "active" : ''}>
                    <button
                      onClick={() => changeSort('family')}
                      onKeyDown={() => changeSort('family')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.plp_refine_dropdown_family ?? 'Family'}
                    </button>
                  </li>

                  <li className={sort === 'all-coffees' ? "active" : ''} >
                    <button
                      onClick={() => changeSort('all-coffees')}
                      onKeyDown={() => changeSort('all-coffees')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'}
                    </button>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"plp_header_refine"+refineState} >
        <button className="plp_header_refine__left" onClick={() => toggleRefineState()} aria-label="close"></button>
        <div className="plp_header_refine__container">
          
          <div className="plp_header_refine__inner">

            <div className="plp_header_refine__header">
              <h4>{allStrings?.plp_refine ?? 'Refine'}</h4>
              <button 
                className="plp_header_refine__close" 
                onClick={() => toggleRefineState()}
                aria-label="close">
              </button>
            </div>

            <div className="plp_header_refine__mobile">
              <label>
                {allStrings?.plp_sort_by ?? 'Sort by'}
              </label>
              <div 
                className="accordion_list open" 
                key={index}>
                <ul className="accordion_list__items">

                  <li className={sort === 'family' ? "active" : ''}>
                    <button
                      onClick={() => changeSort('family')}
                      onKeyDown={() => changeSort('family')}>
                      <span className="accordion_list__square"></span>
                      {allStrings?.plp_refine_dropdown_family ?? 'Family'}
                    </button>
                  </li>

                  <li className={sort === 'all-coffees' ? "active" : ''} >
                    <button
                      onClick={() => changeSort('all-coffees')}
                      onKeyDown={() => changeSort('all-coffees')}>
                      <span className="accordion_list__square"></span>
                      {allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'}
                    </button>
                  </li>

                </ul>
              </div>
              <label>
                {allStrings?.plp_refine ?? 'Refine'}
              </label>
            </div>

            { categories?.map((category, index) => renderCategory(category, index)) }

          </div>
        </div>
      </div>

    </>
  )  
}

export default PlpHeader
