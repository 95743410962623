import { get } from 'lodash'

export const getFilter = (product) => {
  const node = get(product, 'node') ?? product

	if (!node) {
		return []
	}

	// console.log('getFilter', node)

	let filters = []

	// Fazenda

	const fazenda = get(node, 'fazenda.title[0].text')

	if(fazenda) {
		filters.push({
			category: 'fazenda',
			name: fazenda,
			value: fazenda?.toLowerCase()
		})
	}

	// Country 
	
	const country = get(node, 'fazenda.country.title[0].text') 

	if(country) {
		filters.push({
			category: 'country',
			name: country,
			value: country?.toLowerCase()
		})
	}

	// Variety 
	
	const variety = get(node, 'variety[0].text') 

	if(variety) {
		filters.push({
			category: 'variety',
			name: variety,
			value: variety?.toLowerCase()
		})
	}

	// Packaging 
	
	const packaging = get(node, 'packaging[0].text') 

	if(packaging) {
		filters.push({
			category: 'packaging',
			name: packaging,
			value: packaging?.toLowerCase()
		})
	}

	// Processing 
	
	const processing = get(node, 'processing[0].text') 

	if(processing) {
		filters.push({
			category: 'processing',
			name: processing,
			value: processing?.toLowerCase()
		})
	}

	// Harvest Year 
	
	const harvest_year = get(node, 'harvest_year[0].text') 

	if(harvest_year) {
		filters.push({
			category: 'harvest year',
			name: harvest_year,
			value: harvest_year?.toLowerCase()
		})
	}



	// tags_fields.map((tag) => {
	// 	const filter_value = get(tag, 'tag_value[0].text', [])
	// 	filters.push(tag_value);
	// 	return null
	// })







	// const filterList = node.find((b) => (
	// 	b.type === 'tag_list' &&
	// 	get(b, 'primary.tag_list_type[0].text', '').toLowerCase() === 'categories'
	// ))




	// const tagList = node.find((b) => (
	// 	b.type === 'tag_list' &&
	// 	get(b, 'primary.tag_list_type[0].text', '').toLowerCase() === 'categories'
	// ))

	// if (!tagList) {
	// 	return null
	// }

	// const tags_fields = get(tagList, 'fields', [])

	// let tags = []

	// tags_fields.map((tag) => {
	// 	const tag_value = get(tag, 'tag_value[0].text', [])
	// 	tags.push(tag_value);
	// 	return null
	// })

	// // const tags = tags_fields.filter((tag) => {
	// // 	return get(tag, 'tag_value[0].text', [tag])
	// // })

	// // console.log('tags', tags)
	// // const tags = get(tagList, 'fields[0].tag_value', [])

	// if (!tags?.length) {
	// 	return null
	// }

	return filters.map((f) => f)
}
