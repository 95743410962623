// import React, { useMemo } from 'react'
// import { useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { getFilter } from './getFilter'

export const filterdCoffeesPlp = (parsedProducts, selectedFilter) => {

  // class Category {
  //   constructor(name) {
  //     this.name = name
  //     this.arrayFilter = []
  //   }
  //   addFilter(filterName) {
  //     this.arrayFilter.push(filterName)
  //   }
  // }

  let filterdProducts = []

  parsedProducts.map((p) => {

    const productFilters = getFilter(p)

    // console.log('productFilters', productFilters)
    // console.log('selectedFilter', selectedFilter)


    // let objCategory = []
    // let categories = []
    // let result
    // selectedFilter.map((filter) => {
      
    //   const category = get(filter, 'category') 
    //   const filterValue = get(filter, 'value') 
    //   if(objCategory.length === 0) {
    //     objCategory.push(new Category(category))
    //     objCategory[0].addFilter(filterValue)
    //   } else {
    //     result = objCategory.find( ({ name }) => name === category)

    //     if(!result) {
    //       objCategory.push(new Category(category))
    //       objCategory[objCategory.length - 1].addFilter(filterValue)
    //     } else {
    //       let index = objCategory.findIndex(result)
    //       objCategory[index].addFilter(filterValue)
    //     }

    //     console.log('result', result)
    //   }

    //   console.log('objCategory', objCategory)

    //   // if (category && !categories.includes(category)) {
    //   //   categories.push(category)
    //   // }    
    // })

    // selectedFilter.forEach((sf) => {
    //   const filterCategory = sf.category
    //   const filterValue = sf.value

    //   const teste = productFilters.find( ({ category }) => category === filterCategory)

    //   console.log('teste', teste)

    //   // productFilters.forEach((pf) => {
    //   //   const productCategory = pf.category
    //   //   const productValue = pf.value

    //   //   if(c === filterCategory) {
    //   //     if(productCategory === filterCategory && filterValue === filterValue) {
    //   //       categoriesProductMatch++
    //   //     }
    //   //   }
        
    //   // })



    // })


    let categories = []
    selectedFilter.map((filter) => {
      const category = get(filter, 'category') 
      if (category && !categories.includes(category)) {
        categories.push(category)
      }
      
      return null
    })

    const categoriesLength = categories.length
    let categoriesProductMatch = 0
    
    categories.forEach((c) => {

      selectedFilter.forEach((sf) => {
        const filterCategory = sf.category
        const filterValue = sf.value

        if(c === filterCategory) {
          const productFilter = productFilters.find( ({ category }) => category === filterCategory)

          if(productFilter?.value === filterValue) {
            categoriesProductMatch++
          }
        }

      })
    })

    // console.log('categoriesLength', categoriesLength)  
    // console.log('categoriesProductMatch', categoriesProductMatch)  
    
    if(categoriesLength === categoriesProductMatch) {
      filterdProducts.push(p)
    }

    return null
  })

  if (selectedFilter.length < 1) {
    // If Zero filters
    return parsedProducts
  } else {
    // If filters are selected
    return filterdProducts
  }
}
